// Created By Lakshay Sheokand (03-January-2024)

import { Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import React, { useEffect, useState } from 'react';

interface LineChartProps {
	subtitle?: string;
	title?: string;
	xAxisCategories?: any;
	primaryYAxisTitle?: string;
	secondaryYAxisTitle?: string;
	colors?: string[];
	primaryYAxisName?: string;
	secondaryYAxisName?: string;
	xAxisTitle?: string;
	height?: number;
	minHeight?: any;
	backgroundColor?: string;
	showHash?: boolean;
	fromInsights?: boolean;
	seriesData?: SeriesData[];
	color?: any 
}
interface SeriesData {
	name: string;
	data: any[];
	type?: string;
	yAxis?: number;
	showInLegend?: boolean;
	markerEnabled?: boolean;
	tooltipFormat?: string;
	valueSuffix?: string;
}

const LineChart: React.FC<LineChartProps> = (props: any) => {
	NoDataToDisplay(Highcharts);
	const theme: any = useTheme();

	const [options, setOptions]: any = useState({});
	const [isLoading, setIsLoading]: any = useState(true);
	const isScientificNotation = (value: any) => {
		const scientificNotationPattern =
			/^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;
		return scientificNotationPattern.test(value.toString());
	};

	useEffect(() => {
		const tempOptions = {
			chart: {
				plotBackgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
				backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
				borderColor: theme.palette.mode === 'dark' ? 'black' : 'white',
				height: props.height,
				width: props.width,
				className: 'highcharts-color-0',
				style: {
					fontFamily: 'Lato',
					textTransform: 'capitalize',
					fontSize: '14px !important',
					color: '#707070',
				},
				plotBorderWidth: null,
				plotShadow: false,
			},
			title: {
				text: props.title || '',
				style: {
					color: theme.palette.text.primary,
				},
			},

			subtitle: {
				text: props.subtitle || '',
			},

			credits: {
				enabled: false,
			},

			xAxis: {
				categories: props.xAxisCategories || [],
				crosshair: true,
				title: {
					text: props.xAxisTitle || '',
					style: {
						color: theme.palette.text.primary,
					},
				},
				labels: {
					style: {
						color: theme.palette.text.primary,
						fontSize: window.innerWidth === 1920 ? '14px' : '12px',
					},
				},
				plotLines: [
					{
						color: theme.palette.mode === 'dark' ? 'white' : 'black',
						dashStyle: 'dash',
						width: 2,
						value: 11,
						zIndex: 5,
					}],
			},

			legend: {
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				itemMarginBottom: 10,
				itemStyle: {
					fontSize: window.innerWidth === 1920 ? '18px' : '14px',
					color: theme.palette.text.primary,
					fontWeight: '400 !important',
				},
				itemHoverStyle: {
					color: '#707070',
				},
				itemHiddenStyle: {
					color: '#707070',
				},
			},
			lang: {
				noData: props.noDataMessage || 'There is no data in the chart',
			},

			yAxis: [
				{
					// Primary yAxis
					labels: {
						format: props.primaryYAxisFormat || '',
						style: {
							color: theme.palette.text.primary,
							fontSize: window.innerWidth === 1920 ? '14px' : '12px',
						},
					},
					showEmpty: false,
					title: {
						text: props.primaryYAxisTitle || '',
						style: {
							color: theme.palette.text.primary,
							fontSize: window.innerWidth === 1920 ? '18px' : '12px',
						},
					},
					gridLineWidth: 0,
				},
			],
			series: props.seriesData?.map((series: any, index: any) => ({
				showInLegend: series.showInLegend,
				color: series.color,
				name: series.name,
				type: series.type || 'spline',
				yAxis: series.yAxis || 0,
				data: series.data || [],
				marker: {
					enabled: false,
				},
				tooltip: {
					pointFormat:
						series.tooltipFormat ||
						(props.fromInsights
							? props.showHash
								? '<b>#{point.y:.2f}</b>'
								: '<b>${point.y:.2f}</b>'
							: series.name === 'CTR'
							? '<b>{point.y:.2f}%</b>'
							: '<b>{point.y:.2f}</b>'),
					valueSuffix: series.valueSuffix || '',
				},
			})),
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 500,
						},
						chartOptions: {
							legend: {
								layout: 'horizontal',
								align: 'center',
								verticalAlign: 'bottom',
							},
						},
					},
				],
			},
			colors: props.colors
				? props.colors
				: [
						'rgb(108, 99, 255)',
						'rgb(0, 233, 250)',
						'rgb(2, 188, 119)',
						'rgb(224, 124, 36)',
						'rgb(255, 156, 127)',
						// '#213142',
				  ],
		};
		setOptions({ ...tempOptions });
		setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.seriesData]);

	return (
		<>
			{!isLoading ? (
				<Grid
					item
					style={{ minHeight: props.minHeight ? props.minHeight : '400px' }}
					xs={12}
					mt={2}
				>
					<HighchartsReact highcharts={Highcharts} options={options} />
				</Grid>
			) : null}
		</>
	);
};

export default LineChart;
