import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';
import BarGraphLeadsByChannel from '../LeadEstimates/LeadsByChannelGraph';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { display } from '@mui/system';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import ComingSoon from '../../../../components/ComingSoon/ComingSoon';

const ContributingFactors: React.FC<any> = (props: any) => {
	const theme: any = useTheme();
	const [filterByLead, setFilterByLead] = useState<any>('Lead');
	const [selectedAccordionIndex, setSelectedAccordionIndex] = useState<
		number | null
	>(0);
	const [selectedGrade, setSelectedGrade] = useState<any>('All');

	const handleChangeAccordion =
		(index: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setSelectedAccordionIndex(isExpanded ? index : null);
		};

	const FilterByleadChange = (filter: any) => {
		setFilterByLead(filter);
	};

	const getDataForGrade = (grade: string) => {
		switch (grade.toLocaleLowerCase()) {
			case 'grade a':
				setSelectedGrade('A');
				break;
			case 'grade b':
				setSelectedGrade('B');
				break;
			case 'grade c':
				setSelectedGrade('C');
				break;
			case 'overall':
				setSelectedGrade('All');
				break;
			default:
				return null;
		}
	};

	useEffect(() => {
		props.onFiltersChange(selectedGrade, filterByLead);
	}, [selectedGrade, filterByLead]);

	const accordionData = [
		{
			title: `${filterByLead} Conversion by Titles`,
			content: `This metric refers to the anticipated number of deals that will be successfully closed (won) based on the job titles of the key decision-makers or influencers within the prospective customer organizations. Analyzing deals by title can help identify which roles are most influential in driving sales and where to focus outreach efforts. Use this to further refine your ICP and persona definition.`,
			data: [
				{
					name: 'Titles',
					data:
						filterByLead == 'Lead'
							? Object.values(props?.leadData?.[selectedGrade]?.title || [])
							: Object.values(props?.oppData?.[selectedGrade]?.title || []),
				},
			],
			categories:
				filterByLead == 'Lead'
					? Object.keys(props?.leadData?.[selectedGrade]?.title || [])
					: Object.keys(props?.oppData?.[selectedGrade]?.title || []),
			Coming: false,
		},
		{
			title: `${filterByLead} Conversion by Industry`,
			content: `This metric involves forecasting the number of deals expected to close successfully within various industries. By examining this data, businesses can understand which industries are most likely to generate revenue and tailor their marketing and sales strategies to target these sectors more effectively. Use this data to further refine you ABM and personalization for specific industry where we are seeing higher conversions.`,
			data: [
				{
					name: 'Industries',
					data:
						filterByLead == 'Lead'
							? Object.values(props?.leadData?.[selectedGrade]?.industry || [])
							: Object.values(props?.oppData?.[selectedGrade]?.industry || []),
				},
			],
			categories:
				filterByLead == 'Lead'
					? Object.keys(props?.leadData?.[selectedGrade]?.industry || [])
					: Object.keys(props?.oppData?.[selectedGrade]?.industry || []),
			Coming: false,
		},
		{
			title: `${filterByLead} Conversion by Country`,
			content: `This metric forecasts the number of deals expected to close successfully in various countries. By analyzing this data, businesses can gain insights into which geographical markets are most likely to yield successful sales outcomes. This applies only if you have targeting spread across multiple countries.`,
			data: [
				{
					name: 'Countries',
					data:
						filterByLead == 'Lead'
							? Object.values(props?.leadData?.[selectedGrade]?.country || [])
							: Object.values(props?.oppData?.[selectedGrade]?.country || []),
				},
			],
			categories:
				filterByLead == 'Lead'
					? Object.keys(props?.leadData?.[selectedGrade]?.country || [])
					: Object.keys(props?.oppData?.[selectedGrade]?.country || []),
			Coming: true,
		},
	];

	return (
		<Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<div
				className='reportHeading'
				style={{ display: 'flex ', justifyContent: 'space-between' }}
			>
				<Typography variant='h4' component='span'>
					Contributing Factors
				</Typography>
				<Grid
					style={{
						display: 'flex',
						alignContent: 'center',
						flexWrap: 'wrap',
						gap: '20px',
					}}
				>
					<div style={{ width: '200px' }}>
						<Dropdown
							valueSelector='value'
							itemList={['Overall', 'Grade A', 'Grade B', 'Grade C']}
							label=''
							name='Grade'
							onChange={(evt: any) => getDataForGrade(evt.target.value)}
							labelName='Filter By Grade'
							defaultValue={'Overall'}
						/>
					</div>
					<div style={{ width: '200px' }}>
						<Dropdown
							valueSelector='value'
							itemList={['Lead', 'Opportunity']}
							label=''
							name='Filter'
							onChange={(evt: any) => FilterByleadChange(evt.target.value)}
							labelName='Filter By'
							defaultValue={filterByLead}
						/>
					</div>
				</Grid>
			</div>
			<Grid
				container
				bgcolor={theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC'}
				style={{
					padding: '20px',
					display: 'flex',
					justifyContent: 'left',
					borderRadius: '20px',
				}}
			>
				<>
					{' '}
					{props?.isLoading ? (
						<CustomSkeleton
							variant={'rectangular'}
							height={'450px'}
							width={'100%'}
							style={{ borderRadius: '20px' }}
						/>
					) : (
						<>
							<Grid item xs={4} padding={1}>
								{accordionData.map((accordion, index) => (
									<Grid
										item
										xs={12}
										key={index}
										style={{ paddingBottom: '20px' }}
									>
										<Accordion
											expanded={selectedAccordionIndex === index}
											onChange={handleChangeAccordion(index)}
											style={{
												backgroundColor:
													theme.palette.mode === 'dark' ? 'black' : 'white',
												backgroundImage: 'none',
											}}
										>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography variant='h6'>{accordion.title}</Typography>
											</AccordionSummary>
											<AccordionDetails style={{ height: '180px' }}>
												<Typography>{accordion.content}</Typography>
											</AccordionDetails>
										</Accordion>
									</Grid>
								))}
							</Grid>
							{accordionData.map((accordion, index) => (
							selectedAccordionIndex === index && (
								<Grid item xs={8} padding={1} key={index}>
									<div style={{ position: 'relative' }}>
										<Grid style={{opacity : accordion?.Coming ? 0.6 : 1 }}>
										<BarGraphLeadsByChannel
											type='column'
											title={`Predicted ${accordion.title}`}
											yAxisName={'Leads'}
											// color={'#9149ff'}
											series={accordion.data}
											categories={accordion.categories}
										/>
										</Grid>
										{accordion?.Coming && (
											<div
												style={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													backgroundColor: 'rgba(0, 0, 0, 0.8)',
													borderRadius: '20px',
												}}
											>
												<Typography variant='h3' style={{ color: 'white' }}>
													Coming Soon
												</Typography>
											</div>
										)}
									</div>
								</Grid>
							)
						))}
						</>
					)}
				</>
			</Grid>
		</Grid>
	);
};

export default ContributingFactors;
