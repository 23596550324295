import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import useToast from '../../../components/Toast/hooks/useToast';
import WinRate from './LeadEstimates/WinRate';
import GradeEstimates from './LeadEstimates/GradeEstimates';
import LeadsByChannel from './LeadEstimates/LeadsByChannel';
import ImportantMetrix from './LeadEstimates/ImportantMetrix';
import ActualPredictive from './LeadEstimates/ActualPredictive';
import PredictiveScoringEndpoints from '../PredictiveScoringEndpoints';
import useApiService from '../../../services/api.service';
import {
	TableColumns,
	setDataforLeadScoring,
} from './LeadEstimates/LeadScoringData';

const LeadScoring: React.FC<any> = (props: any) => {
	const theme: any = useTheme();
	const toaster: any = useToast();
	const APIService: any = useApiService();
	const [isLoading, setisLoading]: any = useState(true);
	const [tableLimit, setTableLimit]: any = useState(10);
	const [tableOffset, setTableOffset]: any = useState(1);
	const [stage, setStage]: any = useState("Open");
	const [winRateData, setWinRateData] = useState();
	const [gradeEstimatesData, setGradeEstimatesData] = useState();
	const [leadsByChannelData, setleadsByChannelData] = useState();
	const [selectedGradeType, setSelectedGradeType] = useState('overall');
	const [leadForecastData, setleadForecastData]: any = useState();
	const [DateCategories, setDateCategories]: any = useState();
	const [ImportantMetrixData, setImportantMetrixData]: any = useState();
	const getSelectedGradeType = (gradeType: any) => {
		setSelectedGradeType(gradeType);
	};
	const handleLimit = (data: any) => {
		setTableLimit(data); // Set the limit locally
		setTableOffset(Math.ceil((tableOffset * tableLimit) / data)); // Calculate and set the new offset based on current offset and limit
	};

	const handleOffset = (data: any) => {
		setTableOffset(data); // Set the offset locally
	};

	const handleStage = (data: any) => {
		setStage(data);
	};

	useEffect(() => {
		getLeadScoringData();
	}, [tableLimit, tableOffset, props?.dateRange, stage]);

	const [reports, setReports]: any = useState([]);
	const getDataForGrade = (grade: string, graph: any) => {
		const temp = JSON.parse(JSON.stringify(leadForecastData || {}))
		switch (grade) {
			case 'grade a':
				return temp?.A?.[graph];
			case 'grade b':
				return temp?.B?.[graph];
			case 'grade c':
				return temp?.C?.[graph];
			case 'overall':
				return temp?.All?.[graph];
			default:
				return [];
		}
	};

	const data = [
		{
			title: 'Lead Trend',
			actuallabel: 'Actual Leads',
			predictedlabel: 'Predictive Leads',
			actualdata: getDataForGrade(selectedGradeType, 'actual_lead'),
			predictivedata: getDataForGrade(selectedGradeType, 'forecasted_lead'),
		},
		{
			title: 'Revenue Trend',
			actuallabel: 'Actual Revenue',
			predictedlabel: 'Predictive Revenue',
			actualdata: getDataForGrade(selectedGradeType, 'actual_revenue'),
			predictivedata: getDataForGrade(selectedGradeType, 'forecasted_revenue'),
		},
		{
			title: 'Closed Won Trend',
			actuallabel: 'Actual Closed Won',
			predictedlabel: 'Predictive Closed Won',
			actualdata: getDataForGrade(selectedGradeType, 'actual_deal'),
			predictivedata: getDataForGrade(selectedGradeType, 'forecasted_deal'),
		},
	];
	const getLeadScoringData = async () => {
		const request = {
			reqBody: {
				level: 1,
				limit: tableLimit,
				offset: tableOffset,
				start_date: props.dateRange.start_date,
				end_date: props.dateRange.end_date,
				stage: stage ? "Open" : "All",
			},
		};
		try {
			setisLoading(true);
			const res = await APIService.post(
				PredictiveScoringEndpoints.leadScoring(request),
				true
			);
			setData(res);
		} catch (err) {
			toaster.addToast({
				message: 'Kindly, enable Train Model',
				timeout: 3000,
				type: 'error',
			});
			console.error(err);
		} finally {
			setisLoading(false);
		}
	};

	const setData = (res: any) => {
		setWinRateData(res?.average);
		setGradeEstimatesData(res?.lead_score);
		setleadsByChannelData(res?.channel_count);
		setleadForecastData(res?.time_series);
		setDateCategories(res?.time_series?.ds);
		setImportantMetrixData(res?.analysis_dict);
	};

	useEffect(() => {
		setReports(data);
	}, [leadForecastData]);

	return (
		<>
			<Grid
				container
				className='tab_container'
				style={{ marginBottom: '50px' }}
			>
				<WinRate
					setData={setDataforLeadScoring}
					Data={winRateData}
					onGradeTypeChange={getSelectedGradeType}
					isLoading={isLoading}
				/>
				<GradeEstimates
				stagelead={stage}
					Data={gradeEstimatesData}
					grade={selectedGradeType}
					tablecol={TableColumns}
					isLoading={isLoading}
					stage={handleStage}
					limit={handleLimit}
					offset={handleOffset}
				/>
				<LeadsByChannel
					isLoading={isLoading}
					Data={leadsByChannelData}
					title={'Leads By Channel'}
				/>
				{reports.map((report: any, index: number) => (
					<ActualPredictive
						key={index}
						index={index}
						title={report.title}
						actualdata={report.actualdata}
						predicteddata={report.predictivedata}
						actuallabel={report.actuallabel}
						predictedlabel={report.predictedlabel}
						onGradeChange={(selectedGrade: any, index: any) => {
							let tempData = [...reports];
							switch (report.title) {
								case 'Lead Trend':
									tempData[index].actualdata = getDataForGrade(
										selectedGrade,
										'actual_lead'
									);
									tempData[index].predictivedata = getDataForGrade(
										selectedGrade,
										'forecasted_lead'
									);
									break;
								case 'Revenue Trend':
									tempData[index].actualdata = getDataForGrade(
										selectedGrade,
										'actual_revenue'
									);
									tempData[index].predictivedata = getDataForGrade(
										selectedGrade,
										'forecasted_revenue'
									);
									break;
								case 'Closed Won Trend':
									tempData[index].actualdata = getDataForGrade(
										selectedGrade,
										'actual_deal'
									);
									tempData[index].predictivedata = getDataForGrade(
										selectedGrade,
										'forecasted_deal'
									);
									break;
								default:
									break;
							}
							setReports(tempData);
						}}
						datecategories={DateCategories}
						isLoading={isLoading}
					/>
				))}
				<ImportantMetrix Data={ImportantMetrixData ? ImportantMetrixData : []} isLoading={isLoading} />
			</Grid>
		</>
	);
};

export default React.memo(LeadScoring);
