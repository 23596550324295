import * as React from 'react';
import CircularProgress, {
	CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import ToolTipOnText from '../Tooltip/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { display, fontSize } from '@mui/system';

function CircularProgressWithLabel(props: any) {
	const theme: any = useTheme();
	return (
		<Box
			sx={{
				position: 'relative',
				display: 'inline-flex',
				width: '180px',
				height: '180px',
			}}
		>
			<CircularProgress
				variant='determinate'
				style={{
					strokeLinecap: 'round',
					color: '#E600FF',
					strokeWidth: '5',
					position: 'absolute',
					zIndex: 1,
				}}
				{...props}
				size={180}
			/>
			<CircularProgress
				variant='determinate'
				style={{
					strokeLinecap: 'round',
					color: theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC',
					strokeWidth: '5',
					position: 'absolute',
					zIndex: 0,
				}}
				value={100}
				size={180}
			/>
			<Box
				sx={{
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
					zIndex: 2,
				}}
			>
				<Typography
					variant='h5'
					component='div'
					color='text.primary'
					style={{ marginBottom: '30px' }}
				>
					{props.value !== '-' ? `${props.value}%` : props.value}
				</Typography>
				<Typography component='span' color='text.primary' fontSize={13}>
					{props?.data?.text}
				</Typography>
				<Typography component='span' color='text.primary' fontSize={13}>
					({props?.data?.label})
				</Typography>
			</Box>
		</Box>
	);
}

export default function CircularWithValueLabel(props: any) {
	return (
		<CircularProgressWithLabel data={props?.data} value={props?.data?.value} />
	);
}
