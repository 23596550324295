import { Accordion, AccordionSummary } from '@mui/material';
import heartIcon from '../../assets/Images/heart.svg';
import recentQuestionsIcon from '../../assets/Images/RecentQuestions.svg';
const SidebarIcons = () => {
	return (
		<div>
			<Accordion expanded={true}>
				<AccordionSummary aria-controls='panel1-content' id='panel1-header'>
					<div style={{ display: 'flex', gap: '10px' }}>
						<img src={recentQuestionsIcon} />
					</div>
				</AccordionSummary>
			</Accordion>
			<Accordion expanded={true}>
				<AccordionSummary aria-controls='panel1-content' id='panel1-header'>
					<div style={{ display: 'flex', gap: '10px' }}>
						<img src={heartIcon} />
					</div>
				</AccordionSummary>
			</Accordion>
			<Accordion expanded={true}>
				<AccordionSummary aria-controls='panel1-content' id='panel1-header'>
					<div style={{ display: 'flex', gap: '10px' }}>
						<img src={recentQuestionsIcon} />
					</div>
				</AccordionSummary>
			</Accordion>
		</div>
	);
};

export default SidebarIcons;
