import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import './PredictiveScoring.scss';
import LeadEstimates from './channels/LeadScoring';
import OppurtunityScoring from './channels/OppurtunityScoring';
import AccountScoring from './channels/AccountScoring';
import SideBar from '../../components/SideAndTopBar/SideBar';
import headerService from '../../services/header.service';
import { useDateRange } from '../../components/DateTimePicker/DateRangeContext';
import HumanVSAI from './channels/HumanVSAI';
import useApiService from '../../services/api.service';
import useToast from '../../components/Toast/hooks/useToast';
import PredictiveScoringEndpoints from './PredictiveScoringEndpoints';
import AIInsights from './channels/AIInsights';
import { FilterProvider } from './channels/AIINsights.tsx/FIltersContext';

const PredictiveScoring = (): JSX.Element => {
	const [activeTab, setActiveTab] = useState(() => {
		return parseInt(localStorage.getItem('level') || '0');
	});
	const toaster: any = useToast();
	const APIService: any = useApiService();
	const [level, setlevel] = useState(() => {
		return parseInt(localStorage.getItem('level') || '1');
	});
	const { dateRange } = useDateRange();
	const [stage, setStage] = useState(localStorage.getItem('stage') || true);
	const [grade, setGrade] = useState(
		localStorage.getItem('grade') || 'overall'
	);

	const handleTabChange = (event: any, newValue: any) => {
		setActiveTab(newValue);
		localStorage.setItem('level', newValue);
	};

	const handleSidebarToggle = (isOpen: any) => {
		// Handle sidebar toggle if needed
	};

	const sendCSV = async (code: any) => {
		const request = {
			reqBody: {
				level: level + 1,
				start_date: dateRange.start_date,
				end_date: dateRange.end_date,
				stage: stage ? 'Open' : 'All',
				grade:
					grade == 'overall'
						? 1
						: grade == 'grade a'
						? 2
						: grade == 'grade b'
						? 3
						: grade == 'grade c'
						? 4
						: 1,
				sort: 1,
				slack_code: code,
				recipient_email: [],
			},
		};
		try {
			const res = await APIService.post(
				PredictiveScoringEndpoints.leadScoringCSV(request),
				true
			);
		} catch (err) {
			toaster.addToast({
				message: 'Something went wrong',
				timeout: 3000,
				type: 'error',
			});
			console.error(err);
		}
	};

	const completeauth = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get('code');

		if (code) {
			sendCSV(code);
		}
	};

	useEffect(() => {
		completeauth();
	}, []);

	// Update stage and grade whenever they change in localStorage
	useEffect(() => {
		const stageFromLocalStorage = localStorage.getItem('stage') || true;
		const gradeFromLocalStorage = localStorage.getItem('grade') || 'overall';
		setStage(stageFromLocalStorage);
		setGrade(gradeFromLocalStorage);
	}, [localStorage.getItem('stage'), localStorage.getItem('grade')]);

	return (
		<>
		<FilterProvider>
			<div
				className='Advertisement_channel_effect'
				style={{ width: '100%', padding: '20px' }}
			>
				<SideBar onSidebarToggle={handleSidebarToggle} />
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: '64px',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							margin: '0px 20px',
						}}
					>
						{/* Train Model */}
						{/* <SwitchButton
                            checked={mediaMixToggleButton}
                            selectedChecked={mediaMixToggleButton}
                            value={mediaMixToggleButton}
                            onChange={(event: any) => {}}
                        /> */}
					</div>
				</div>
				<Tabs
					value={activeTab}
					onChange={handleTabChange}
					aria-label='Predictive Scoring Tabs'
					className='custom-tabs'
				>
					<Tab label='Lead Scoring' />
					<Tab label='Opportunity Scoring' />
					<Tab label='Account Scoring' />
					<Tab label='Human V/s AI' />
					<Tab label='AI Insights'/>
				</Tabs>
				<div className='ppc__scrollArea'>
					{activeTab === 0 && <LeadEstimates dateRange={dateRange} />}
					{activeTab === 1 && <OppurtunityScoring dateRange={dateRange} />}
					{activeTab === 2 && <AccountScoring dateRange={dateRange} />}
					{activeTab === 3 && <HumanVSAI dateRange={dateRange} />}
					{activeTab === 4 && <AIInsights dateRange={dateRange}/>}
				</div>
			</div>
			</FilterProvider>
		</>
	);
};

export default PredictiveScoring;
