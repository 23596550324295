import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import heartIcon from '../../../assets/Images/heart.svg';
import recentQuestionsIcon from '../../../assets/Images/RecentQuestions.svg';
import faqQuestionsIcon from '../../../assets/Images/question-square.svg';
import { useEffect, useState } from 'react';
import { getQuestionHistory } from '../AIChatBotApiHit/AiChatBotApiHit';
import headerService from '../../../services/header.service';
import './AIChatBotQuestions.scss';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import ToolTipOnText from '../../../components/Tooltip/Tooltip';
const AIChatBotQuestions = () => {
	const theme = useTheme();
	// ------------------ Hooks --------------------------------
	const [questions, setQuestions]: any = useState({
		recentQuestions: [],
		favouriteQuestions: [],
		faqQuestions: [],
	});

	const [accordionExpand, setAccordionExpand]: any = useState({
		recentQuestionExpand: true,
		favQuestionExpand: true,
		faqQuestionExpand: false,
	});

	const [disableAccordionQuestion, setDisableAccordionQuestion]: any =
		useState(false);

	// ---------------------------------------------------------

	// ----------------- Functions -----------------------------

	function modifyArray(arr: any, element: any) {
		if (arr.length === 10) {
			arr.pop(); // Remove the last element if array length is 10
		}

		arr.unshift(element); // Add the new element at the beginning of the array
		return arr;
	}

	const eventFromService = (eventData: any) => {
		let tempQuestionsArray: any = { ...questions };
		let foundedIndex = tempQuestionsArray.favouriteQuestions.findIndex(
			(item: any) => item.qid == eventData.data.qid
		);
		switch (eventData.type) {
			case 'historyQuestions':
				setQuestions({
					faqQuestions: eventData?.data.df_faq,
					recentQuestions: eventData?.data.df_history,
					favouriteQuestions: eventData?.data.df_fav,
				});
				break;
			case 'disableNewQuestion':
				setDisableAccordionQuestion(eventData.data);
				break;
			case 'favouriteQuestion':
				setQuestions((prevState: any) => ({
					...prevState,
					favouriteQuestions: modifyArray(
						tempQuestionsArray.favouriteQuestions,
						eventData.data
					),
				}));
				break;
			case 'unFavouriteQuestion':
				if (foundedIndex != -1) {
					tempQuestionsArray.favouriteQuestions.splice(foundedIndex, 1);
					setQuestions(tempQuestionsArray);
				}
				break;
			case 'addNewRecentQuestion':
				setQuestions((prevState: any) => ({
					...prevState,
					recentQuestions: modifyArray(
						tempQuestionsArray.recentQuestions,
						eventData.data
					),
				}));
				break;
			default:
				break;
		}
	};

	const handleQuestionClick = (question: any) => {
		setDisableAccordionQuestion(true);
		setTimeout(() => {
			const questionFromAccord = {
				type: 'newQuestionAsked',
				data: question,
			};
			headerService.emit(questionFromAccord);
		});
	};

	const toggleAccordion = (accordionType: string) => {
		// Clone the current accordionExpand state
		const tempAccordionExpand = { ...accordionExpand };

		// Toggle the state of the clicked accordion
		tempAccordionExpand[accordionType] = !tempAccordionExpand[accordionType];

		// Count the number of open accordions
		const openAccordionCount = Object.values(tempAccordionExpand).filter(
			(isOpen) => isOpen
		).length;

		// If more than two accordions are open, find the first open accordion and close it
		if (openAccordionCount > 2) {
			let foundOpenAccordion = false;
			for (const key in tempAccordionExpand) {
				if (
					tempAccordionExpand[key] &&
					!foundOpenAccordion &&
					key !== accordionType
				) {
					tempAccordionExpand[key] = false;
					foundOpenAccordion = true;
				}
			}
		}

		// Update the accordionExpand state
		setAccordionExpand(tempAccordionExpand);
	};

	// ---------------------------------------------------------

	// ----------------- Useeffect -----------------------------
	useEffect(() => {
		getQuestionHistory();
	}, []);

	useEffect(() => {
		let headerSubscription = headerService?.subscribe(eventFromService);
		return () => {
			headerSubscription?.unsubscribe();
		};
	});

	// ---------------------------------------------------------

	return (
		<div className='recentQuestions'>
			<Accordion
				onChange={() => toggleAccordion('recentQuestionExpand')}
				expanded={accordionExpand.recentQuestionExpand}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1-content'
					id='panel1-header'
				>
					<div style={{ display: 'flex', gap: '10px' }}>
						<img src={recentQuestionsIcon} /> Recent Questions
					</div>
				</AccordionSummary>
				{/* style={{ backgroundColor: '' }} */}
				<AccordionDetails
					style={{
						backgroundColor:
							theme.palette.mode === 'dark' ? '#000000' : '#F4F7FC',
					}}
				>
					{questions.recentQuestions.map((item: any, index: any) => (
						<div
							style={{
								pointerEvents: disableAccordionQuestion ? 'none' : 'auto',
								cursor: disableAccordionQuestion
									? 'not-allowed !important'
									: 'pointer',
							}}
							onClick={() => {
								handleQuestionClick(item);
							}}
							className='historyQuestion'
						>
							<ToolTipOnText title={item.question}>
								{item.question.length > 20
									? `${item.question.slice(0, 33)}...`
									: item.question}
							</ToolTipOnText>
						</div>
					))}
				</AccordionDetails>
			</Accordion>
			<Accordion
				onChange={() => toggleAccordion('favQuestionExpand')}
				expanded={accordionExpand.favQuestionExpand}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1-content'
					id='panel1-header'
				>
					<div style={{ display: 'flex', gap: '10px' }}>
						<img src={heartIcon} /> Favourite Questions
					</div>
				</AccordionSummary>
				<AccordionDetails
					style={{
						backgroundColor:
							theme.palette.mode === 'dark' ? '#000000' : '#F4F7FC',
					}}
				>
					{questions.favouriteQuestions.map((item: any, index: any) => (
						<div
							style={{
								pointerEvents: disableAccordionQuestion ? 'none' : 'auto',
								cursor: disableAccordionQuestion
									? 'not-allowed !important'
									: 'pointer',
							}}
							onClick={() => {
								handleQuestionClick(item);
							}}
							className='historyQuestion'
						>
							<ToolTipOnText title={item.question}>
								{item.question.length > 20
									? `${item.question.slice(0, 33)}...`
									: item.question}
							</ToolTipOnText>
						</div>
					))}
				</AccordionDetails>
			</Accordion>
			<Accordion
				onChange={() => toggleAccordion('faqQuestionExpand')}
				expanded={accordionExpand.faqQuestionExpand}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1-content'
					id='panel1-header'
				>
					<div style={{ display: 'flex', gap: '10px' }}>
						<img src={faqQuestionsIcon} /> FAQs
					</div>
				</AccordionSummary>
				<AccordionDetails
					style={{
						backgroundColor:
							theme.palette.mode === 'dark' ? '#000000' : '#F4F7FC',
					}}
				>
					{questions.faqQuestions.map((item: any, index: any) => (
						<div
							style={{
								pointerEvents: disableAccordionQuestion ? 'none' : 'auto',
								cursor: disableAccordionQuestion
									? 'not-allowed !important'
									: 'pointer',
							}}
							onClick={() => {
								handleQuestionClick(item);
							}}
							className='historyQuestion'
						>
							<ToolTipOnText title={item.question}>
								{item.question.length > 30
									? `${item.question.slice(0, 30)}...`
									: item.question}
							</ToolTipOnText>
						</div>
					))}
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default AIChatBotQuestions;
