import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
// import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import diggGrowthLogoDark from '../../assets/Images/digGrowthLogo.svg';
import diggGrowthLogoLight from '../../assets/Images/digGrowthLogoLight.svg';
import diggGrowthLogosmall from '../../assets/Logo/favicon.svg';
import diggGrowthLightsmall from '../../assets/Logo/faviconlight.svg';
import collapseSideBarIcon from '../../assets/Images/collapseSideBar.svg';
import './SideBar.scss';
import Navigation from './Navigation/Naivgation';
import RightNavigationIcons from './RightNavigation/RightNavigation';
import AIChatBotQuestions from '../../containers/AIChatBot/AIChatBotQuestions/AIChatBotQuestions';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import SidebarIcons from './SidebarIcons';
import useLocalStorage from '../../utils/localStorage';
import { width } from '@mui/system';

const drawerWidth = 320;

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		// easing: theme.transitions.easing.sharp,
		// duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			// easing: theme.transitions.easing.easeOut,
			// duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

interface SideBarProps {
	onSidebarToggle: (isOpen: boolean) => void;
}

export default function SideBar({ onSidebarToggle }: SideBarProps) {
	const theme = useTheme();
	const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
	// const [open, setOpen] = React.useState(true);

	const isChatbot=
		window.location.pathname == '/insights/chatbot';

	const handleDrawer = () => {
		// setOpen(!open);
		setIsSidebarOpen(!isSidebarOpen);
		onSidebarToggle(!isSidebarOpen);
	};
	const isClient = useLocalStorage.getItem('isClient');
	const Data = useLocalStorage.getItem('Client_data');
	const DSLogo: any = Data?.DSLogo;
	const LSLogo: any = Data?.LSLogo;
	const DOSLogo: any = Data?.DOSLogo;
	const LOSLogo: any = Data?.LOSLogo;
	return (
		<Box sx={{ display: 'flex', boxShadow: 'none !important' }}>
			{/* <CssBaseline /> */}
			<AppBar
				className='sideBar 5'
				style={{
					backgroundColor:
						theme.palette.mode === 'dark' ? '#000000' : 'rgb(244, 247, 252)',
					boxShadow: 'none !important',
					border: 'none !important',
				}}
				position='fixed'
				open={isSidebarOpen}
				// open={open}
			>
				<Toolbar
					className='toolBar'
					style={{
						marginLeft: isSidebarOpen ? '0px' : '60px',
						backgroundColor:
							theme.palette.mode === 'dark' ? '#000000' : '#F4F7FC',
					}}
				>
					<div style={{ display: 'flex' }}>
						{!isChatbot ? (
							<></>
						) : (
							<div style={{ display: 'flex' }} onClick={handleDrawer}>
								<img src={collapseSideBarIcon} />
							</div>
						)}
						<div style={{ marginLeft: '20px' }}>
							<Navigation />
						</div>
					</div>
					<RightNavigationIcons />
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: isSidebarOpen ? drawerWidth : '65px',
						boxSizing: 'border-box',
						transform: isSidebarOpen ? 'translateX(0)' : `translateX(-65px)`,
						transition: theme.transitions.create('margin', {
							// easing: theme.transitions.easing.sharp,
							// duration: theme.transitions.duration.enteringScreen,
						}),
						overflowY: "hidden",
						height: !isChatbot ? '65px' : '100%',
						border: !isChatbot ? 'none' : '',
					},
				}}
				// className='sideBar'
				className={theme.palette.mode === 'dark' ? 'sideBar' : 'sideBarLight'}
				variant='persistent'
				anchor='left'
				// open={open}
				open={isSidebarOpen}
			>
				<DrawerHeader
					style={{ alignSelf: 'center', boxShadow: 'none !important' }}
				>
					{isClient ? (
							<img
							src={theme.palette.mode === 'dark' ? DSLogo : LSLogo}
							alt='Logo'
						/>
					) : (
						<img
							src={
								theme.palette.mode === 'dark'
									? diggGrowthLogoDark
									: diggGrowthLogoLight
							}
							alt='Company Logo'
							style={{ height: '55px', width: '90%' }}
						/>
					)}
				</DrawerHeader>

				{!isChatbot ? (
					<></>
				) : (
					<>
						<Divider />

						<List>
							<AIChatBotQuestions />
						</List>
					</>
				)}
			</Drawer>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: isSidebarOpen ? drawerWidth : '65px',
						boxSizing: 'border-box',
						transform: isSidebarOpen ? 'translateX(0)' : `translateX(-65px)`,
						transition: theme.transitions.create('margin', {
							// easing: theme.transitions.easing.sharp,
							// duration: theme.transitions.duration.leavingScreen,
						}),
					},
				}}
				// className='sideBar'
				className={theme.palette.mode === 'dark' ? 'sideBar' : 'sideBarLight'}
				variant='persistent'
				anchor='left'
				// open={open}
				open={!isSidebarOpen}
			>
				<div
					style={{
						display: 'flex',
						height: '64px',
						justifyContent: 'space-evenly',
						padding: '5px',
					}}
				>
					{isClient ? (
					
						<img
							src={theme.palette.mode === 'dark' ? DOSLogo : LOSLogo}
							alt='Logo'
							style={{width: "48px", height: "48px"}}
						/>
					) : (
						<img
							src={
								theme.palette.mode === 'dark'
									? diggGrowthLogosmall
									: diggGrowthLightsmall
							}
							alt='Company Logo'
							style={{ height: '55px', width: '55px' }}
						/>
					)}
				</div>
				<Divider />
				<List style={{ marginTop: '70px' }}>
					<SidebarIcons />
				</List>
			</Drawer>
		</Box>
	);
}
