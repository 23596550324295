/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import './DataQualityCard.scss';
import CircularProgress, {
	CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FeedbackIcon from '@mui/icons-material/Feedback';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, {
	linearProgressClasses,
} from '@mui/material/LinearProgress';
import { useTheme } from '@mui/styles';
import twoDecimal from '../../utils/decimalPercentage';
import CustomDialog from '../../containers/AdvertisementChannel/channels/AIINsights.tsx/CustomDialog';
// import { createTheme, ThemeProvider } from "@mui/material/styles";
const getColors = (value: any) => {
	if (value >= 15) return '#FF003D';
	// if (value <= 89) return '#F5A149';
	return '#02BC77';
};

const getColorsforTwoCards = (value: any) => {
	if (value <= 0 || null) return '#02BC77';
	if (value >= 0 && value <= 49) return '#F5A149';
	return '#FF003D';
};

export function CircularProgressWithLabel(
	props: CircularProgressProps & {
		value: number;
		fontSize?: string;
		roundNum?: boolean;
		hideDangerBg?: boolean;
		percetageDescription?: string;
		labelStyle?: any;
		ringColor?: any;
		ownTheme?: any;
		currentGrade?: any;
		name?: any;
	}
) {
	const theme: any = useTheme();
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress
				size='50px'
				thickness={4}
				variant='determinate'
				{...props}
				style={
					props.value === 0
						? props?.hideDangerBg
							? {
									color:
										theme.palette.mode === 'dark'
											? theme.palette.text.alt
											: 'black',
									background: theme.palette.background.light,
							  }
							: {
									color: theme.palette.text.alt,
									background:
										props.name === 'Missing Closed Date' ||
										props.name === 'Closed Date Before Creation Date'
											? '#02BC77'
											: '#FF003D',
							  }
						: {
								color:
									props.name === 'Missing Closed Date' ||
									props.name === 'Closed Date Before Creation Date'
										? getColorsforTwoCards(props.value)
										: getColors(props.value),
						  }
				}
				className='circular-progress-status'
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					...(props?.labelStyle ? props.labelStyle : {}),
				}}
			>
				<Typography
					variant='caption'
					component='div'
					color='text.secondary'
					style={
						props.value === 0
							? {
									color:
										theme.palette.mode === 'dark'
											? theme.palette.text.alt
											: 'black',
									fontWeight: '600',
									fontSize: '0.9rem',
							  }
							: { fontWeight: '600', fontSize: '0.9rem' }
					}
				>{`${
					props.value < 1 ? props.value : Math.round(props.value)
				}%`}</Typography>
				<br />
				{props?.percetageDescription ? (
					<Typography>{props.percetageDescription}</Typography>
				) : (
					<></>
				)}
			</Box>
		</Box>
	);
}

const ConstructLineBasedOnValue = (value: any) => {
	const getColors = () => {
		if (value <= 49) return '#FF003D';
		if (value <= 89) return '#F5A149';
		if (value !== 0 && value) return '#02BC77';
		return 'transparent';
	};

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		backgroundColor: '#92919130',
		color: 'red',
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: getColors(),
		},
	}));
	return (
		<BorderLinearProgress
			variant='determinate'
			value={value}
			classes={{
				root: 'linear__loader__custom',
			}}
		/>
	);
};

interface iDataQualityCards {
	cardsData: any[];
}

const DataQalityCards: React.FunctionComponent<iDataQualityCards> = ({
	cardsData,
}): JSX.Element => {
	const theme: any = useTheme();
	let [dialo, setDialo] = useState(false);
	const [showDescription, setShowDescription] = useState<any>({});
	const sortedArr = (arr: any[]) => {
		try {
			return arr;
		} catch (err) {
			return [];
		}
	};

	const assignClassForbg = (item: any) => {
		if (typeof item.status === 'number' && !item.hasOwnProperty('isNumber')) {
			if (item.status > 15 && item.status <= 100) {
				return 'error_bg';
			} else if (item.status <= 15) {
				return 'success_bg';
			} else {
				return 'warning_bg';
			}
		} else if (
			typeof item.status === 'number' &&
			item.hasOwnProperty('isNumber')
		) {
			if (item.status > 15 && item.status <= 100) {
				return 'error_bg';
			} else if (item.status <= 15) {
				return 'success_bg';
			}
		} else {
			return 'warning_bg';
		}
	};

	const [isHovered, setIsHovered] = useState(false);

	const handleClick = (data: any) => {
		setDialo(data);
	};

	const handleClose = (data: any) => {
		console.log('i ma here');
		setDialo(false);
	};

	console.log(dialo, 'wowowo');
	return (
		<>
			<div className='data-quality-main'>
				<Grid className='Data-quality-card-container' container spacing={2}>
					{sortedArr(cardsData).map((item: any, index: number) => (
						<Grid
							className={`${'Data-quality-card'} dq_card_${index} ${assignClassForbg(
								item
							)}`}
							style={{
								background: theme.palette.mode == 'dark' ? 'black' : 'white',
							}}
							item
							xs={2.8}
							key={item.name + '' + `${item.discription}_dq_card_${index}`}
							onMouseEnter={() => {
								setShowDescription((previousData: any) => ({
									...previousData,
									[`${item.name}`]: true,
								}));
							}}
							onMouseLeave={() => {
								setTimeout(() => {
									setShowDescription((previousData: any) => ({
										...previousData,
										[`${item.name}`]: false,
									}));
								}, 200);
							}}
							onClick={() => {
								(item.status === 'table'&& dialo == false) && handleClick(true) ;
							}}
						>
							{(!item.isNumber &&
								item.status >= 0 &&
								typeof item.status !== 'boolean' &&
								item?.range) ||
							(item.status < 1 &&
								item.status >= 0 &&
								typeof item.status !== 'boolean' &&
								item?.range) ? (
								<div
									className={`circular-progress ${
										showDescription[`${item.name}`]
											? 'hideTopStatus'
											: 'showTopStatus'
									}`}
								>
									<CircularProgressWithLabel
										value={
											item.range.value == null
												? 0
												: twoDecimal(
														(item.range.value / item.range.total) * 100
												  )
										}
										size='80px'
										thickness={6}
										name={item.name}
									/>
								</div>
							) : item.status === true ? (
								<div
									className={`circular-progress-status pass ${
										showDescription[`${item.name}`]
											? 'hideTopStatus'
											: 'showTopStatus'
									}`}
								>
									Pass
								</div>
							) : item.status === false ? (
								<div
									className={`circular-progress-status fail ${
										showDescription[`${item.name}`]
											? 'hideTopStatus'
											: 'showTopStatus'
									}`}
								>
									Fail
								</div>
							) : item.status === 'table' ? (
								''
							) : (
								<div
									className={`circular-progress-status ${
										showDescription[`${item.name}`]
											? 'hideTopStatus'
											: 'showTopStatus'
									}`}
									style={{ background: '#80808059' }}
								>
									{item.status == 'Data not available'
										? '...'
										: item.status || '...'}
								</div>
							)}
							<div className='title_and_desc_container'>
								<Typography
									className={`width_95_margin_0_auto dQheading ${
										showDescription[`${item.name}`] ? 'hideTitle' : 'showTitle'
									}`}
									id='campaignPerformance'
									variant='h6'
									component='div'
								>
									{item.name}
								</Typography>
								{item.status == 'table' ? (
									<>
										<TrendingFlatIcon
											style={{ alignSelf: 'flex-end', marginRight: '10px' }}
										/>
										<CustomDialog
											onOpen={dialo}
											onClose={handleClose}
											data={item}
										/>
									</>
								) : null}
								<Fade
									in={showDescription[`${item.name}`]}
									style={{ transformOrigin: '0 0 0' }}
									{...(showDescription[`${item.name}`] ? { timeout: 500 } : {})}
								>
									<Typography
										className={`width_95_margin_0_auto dQpara ${
											!showDescription[`${item.name}`]
												? 'hideDescription'
												: 'showDescription'
										}`}
										id='campaignPerformance'
										variant='body1'
										component='div'
									>
										{' '}
										{item.status == 'Data not available'
											? 'Data not available'
											: item.discription}
										<div className={`status_icon ${assignClassForbg(item)}`}>
											{assignClassForbg(item) === 'error_bg' ? (
												<ThumbDownIcon style={{ fontSize: '4rem' }} />
											) : assignClassForbg(item) === 'success_bg' ? (
												<ThumbUpIcon style={{ fontSize: '4rem' }} />
											) : (
												<FeedbackIcon style={{ fontSize: '4rem' }} />
											)}
										</div>
									</Typography>
								</Fade>
							</div>
						</Grid>
					))}
				</Grid>
			</div>
		</>
	);
};

export default React.memo(DataQalityCards);
