import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import useToast from '../../../components/Toast/hooks/useToast';
import PredictiveScoringEndpoints from '../PredictiveScoringEndpoints';
import useApiService from '../../../services/api.service';
import ScoreBoard from './ScoreBoards/ScoreBoard';
import QuickInsights from './AIINsights.tsx/QuickInsights';
import ContributingFactors from './AIINsights.tsx/ContributingFactors';
import DataProfiling from './AIINsights.tsx/DataProfiling';
import ConfusionMatrix from './AIINsights.tsx/ConfusionMatrix';
import { useFilterContext } from './AIINsights.tsx/FIltersContext';
const AIInsights: React.FC<any> = (props: any) => {
	const theme: any = useTheme();
	const toaster: any = useToast();
	const APIService: any = useApiService();
	const [selectedStages, setselectedStages] = useState('All');
	const [quickInsightsData, setquickInsightsData] = useState([]);
	const [contributingFactorsDataLead, setcontributingFactorsDataLead] =
		useState([]);
	const [contributingFactorsDataOpp, setcontributingFactorsDataOpp] = useState(
		[]
	);
	const { selectedValues , limit ,offset } = useFilterContext();
	const [DataProfilingData, setDataProfilingData] = useState([]);
	const [ConfusionMatrixDataLead, setConfusionMatrixDataLead] = useState([]);
	const [ConfusionMatrixDataOpp, setConfusionMatrixDataOpp] = useState([]);
	const [isLoading, setisLoading]: any = useState(true);
	const [tableLimit, setTableLimit]: any = useState(10);
	const [tableOffset, setTableOffset]: any = useState(1);
	const [selectedGrade, setSelectedGrade] = useState('All'); // Initial value
	const [filterByLead, setFilterByLead] = useState('Lead');
	const handleStageChange = (data: any) => {
		setselectedStages(data);
	};
	const handleLimit = (data : any) => {
		setTableLimit(data);
		setTableOffset(Math.ceil((tableOffset * tableLimit) / data));
	};
	const handleOffset = (data :any) => {
		setTableOffset(data);
	};
	const getLeadScoringData = async () => {
		try {
			setisLoading(true);
			const request = {
				reqBody: {
					level: 1,
					limit: tableLimit,
					offset: tableOffset,
					start_date: props.dateRange.start_date,
					end_date: props.dateRange.end_date,
					sort: 1,
					countries: selectedValues[2],
					industries: selectedValues[1],
					grades: selectedValues[0],
				},
			};
			const res = await APIService.post(
				PredictiveScoringEndpoints.aiinsights(request),
				true
			);
			setData(res?.data);
		} catch (err) {
			toaster.addToast({
				message: 'Kindly, enable Train Model',
				timeout: 3000,
				type: 'error',
			});
			console.error(err);
		} finally {
			setisLoading(false);
		}
	};

	const handleFiltersChange = (grade: any, filter: any) => {
		setSelectedGrade(grade);
		setFilterByLead(filter);
	};

	useEffect(() => {
		getLeadScoringData();
	}, [props?.dateRange,tableLimit, tableOffset, selectedValues]);
	useEffect(() => {
		handleLimit(limit)
		handleOffset(offset)
	}, [limit , offset]);
	const setData = (res: any) => {
		setquickInsightsData(res?.quick_insights);
		setcontributingFactorsDataLead(res?.all_counts_lead);
		setcontributingFactorsDataOpp(res?.all_counts_opp);
		setDataProfilingData(res);
		setConfusionMatrixDataLead(res?.all_matrices_lead);
		setConfusionMatrixDataOpp(res?.all_matrices_opp);
	};
	
	return (
		<>
			<Grid
				container
				className='tab_container'
				style={{ marginBottom: '50px' }}
			>
				<QuickInsights data={quickInsightsData} isLoading={isLoading}/>
				<ContributingFactors
					leadData={contributingFactorsDataLead}
					oppData={contributingFactorsDataOpp}
					onFiltersChange={handleFiltersChange}
					isLoading={isLoading}
				/>
				<ConfusionMatrix
					leadData={ConfusionMatrixDataLead}
					oppData={ConfusionMatrixDataOpp}
					grade={selectedGrade}
					filterByLead={filterByLead}
					isLoading={isLoading}
				/>
				<DataProfiling data={DataProfilingData} isLoading={isLoading}/>
			</Grid>
		</>
	);
};
export default React.memo(AIInsights);
