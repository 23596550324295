import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import useLocalStorage from '../../utils/localStorage';
import ToolTipOnText from '../../components/Tooltip/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import useToast from '../../components/Toast/hooks/useToast';
import LogoutIcon from '../../assets/Images/LogoutIcon.svg';
import EditProfileEndPoints from '../EditProfile/EditProfileEndpoints';
import useLoader from '../../hooks/useLoader';
import useApiService from '../../services/api.service';
import AlertDialog from '../../components/Dialog/Dialog';
import '../../components/Sidebar/AccountSettings.scss';
import EditProfileIcon from '../../assets/Images/EditProfileIcon.svg';
import { useNavigate } from 'react-router';
import AIEditProfile from '../EditProfile/AIEditProfile';

const ProfileLogo = () => {
	const navigate = useNavigate();
	const theme: any = useTheme();
	const userData: any = useLocalStorage.getItem('userData');
	const name: any = userData?.name?.split(' ');
	const firstName: any = (name && Array.from(name[0])[0]) || '  ';
	const lastnAME: any = (name && name[1] && Array.from(name[1])[0]) || '  ';
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
		setIsClicked(false);
	};
	const [isClicked, setIsClicked] = useState(false);
	const APIService = useApiService();
	const confirmlogOutRef: any = React.useRef();
	const toaster = useToast();
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setIsClicked(!isClicked);
	};
	const loader: any = useLoader();
	const onLogOut = async () => {
		let isLogout = await logoutSessionFromKeyCloak();
		if (isLogout) {
			window.location.href = '/login';
			const embeded: any = localStorage.getItem('embeded') || false;
			const appTheme = localStorage.getItem('appTheme') || 'light';
			localStorage.clear();
			localStorage.setItem('embeded', embeded);
			localStorage.setItem('appTheme', appTheme);
		}
	};

	const logoutSessionFromKeyCloak = async () => {
		loader.showLoader();
		let request = {
			refresh_token: userData['refresh-token'],
		};
		return await APIService.get(EditProfileEndPoints.logoutUser(request))
			.then((res: any) => {
				loader.hideLoader();
				return true;
			})
			.catch((err: any) => {
				loader.hideLoader();
				toaster.addToast({
					message: err.message || 'Something went wrong',
					timeout: 2000,
					type: 'error',
				});
				return false;
			});
	};

	return (
		<>
			<ToolTipOnText title={userData?.name}>
				{/* <div className='accountSettings__imageBorder'> */}
				<IconButton
					className='accountSettings__icon'
					sx={{
						// border: `1px solid ${theme.palette.secondary.dark}`,
						height: '33px',
						width: '35px',
						background: 
							 `linear-gradient(63.53deg, #9149ff 16.62%, #e600ff 83.38%),
							linear-gradient(
								0deg,
								rgba(145, 73, 255, 0.01),
								rgba(145, 73, 255, 0.01)
							)`,
					}}
					onClick={handleClick}
				>
					<div
						style={{
							color: theme.palette.mode === 'dark' ? 'white' : 'black',
							backgroundColor: theme.palette.mode === 'dark' ? 'black' : '#fff',
							padding: '5px',
							borderRadius: '25px',
							borderColor: theme.palette.mode === 'dark' ? '' : 'black',
						}}
					>
						{` ${firstName}${lastnAME}  `}
					</div>
				</IconButton>
				{/* </div> */}
			</ToolTipOnText>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						bgcolor: theme.palette.mode == 'dark' ? '#24282F' : 'white',
						boxShadow: '0px 2px 8px rgba(0,0,0,0.32)',
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem
					onClick={() => {
						// navigate()
						navigate('/aieditProfile');
						// navigate('/editProfile');
					}}
				>
					<ListItemIcon>
						<img src={EditProfileIcon} />
					</ListItemIcon>
					{/* Delete Account */}
					<div style={{ fontFamily: 'Poppins' }}>Edit Profile</div>
				</MenuItem>
				<Divider />
				<MenuItem
					onClick={() => {
						confirmlogOutRef.current.handleClickOpen();
					}}
				>
					<ListItemIcon>
						<img src={LogoutIcon} />
					</ListItemIcon>
					<div style={{ fontFamily: 'Poppins' }}>Logout</div>
				</MenuItem>
			</Menu>
			<React.Suspense fallback=''>
				<AlertDialog
					ref={confirmlogOutRef}
					title='Confirm Logout'
					confirm='logout'
					discard='Cancel'
					onConfirm={onLogOut}
					onDiscard={() => {
						confirmlogOutRef.current.handleClose();
					}}
					hideCloseButton
					color='error'
					description='Are you sure you want to Logout ?'
				/>
			</React.Suspense>
		</>
	);
};

export default ProfileLogo;
